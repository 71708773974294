<template>
    <section :id="cmsBlock.anchorTag" :class="cmsBlock.namedListStyle" class="component-container">
        <div class="named-list" :class="cmsBlock.listItemStyle">
            <div v-for="item in cmsBlock.namedListItems" class="list-item" :key="item.id">
                <div class="key">{{ localizeAlt(item, 'name') }}</div>
                <div class="separator">
                    <img :src="separator"/>
                </div>
                <div class="value">{{ localizeAlt(item, 'text') }}</div>
            </div>
            <a :href="localize(cmsBlock.buttonLink)" v-if="cmsBlock.addButton && !isInternalLink(localize(cmsBlock.buttonLink))">
                <button class="button-dark">
                    {{ localize(cmsBlock.buttonText) }}
                </button>
            </a>
            <router-link :to="localize(cmsBlock.buttonLink)" v-if="cmsBlock.addButton && isInternalLink(localize(cmsBlock.buttonLink))">
                <button class="button-dark">
                    {{ localize(cmsBlock.buttonText) }}
                </button>
            </router-link>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'NamedListBlock',

        props: [
            'cmsBlock'
        ],

        computed: {
            separator() {
                switch(this.cmsBlock.listItemStyle){
                    case 'arrows':
                        return require('@/assets/triangle.svg')
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.component-container {
    .named-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: $container-width;
        margin: 0 auto;
        padding: 0 getSpacing('padding-default-horizontal', 'desktop');

        @include breakpoint('tablet') {
            padding: 0 getSpacing('padding-default-horizontal', 'tablet');
        }
        @include breakpoint('mobile') {
            padding: 0 getSpacing('padding-default-horizontal', 'mobile');
        }

        .list-item {
            display: grid;
            grid-template-columns: 0.75fr 0.25fr 2fr;
            gap: 0px 10px;
            margin-bottom: 30px;
            margin-right: 80px;
            width: 100%;

            @include breakpoint('mobile') {
                grid-template-columns: 1fr;
                margin-right: unset;
            }

            .key {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                font-size: getFontSize('defaultText', 'desktop');
                font-weight: 600;
                font-family: getFontFamily('defaultText');
                

                @include breakpoint('tablet') {
                    font-size: getFontSize('defaultText', 'tablet');
                }
                @include breakpoint('mobile') {
                    font-size: getFontSize('defaultText', 'mobile');
                    text-align: left;
                    justify-content: flex-start;
                    margin-bottom: 10px;
                }
            }

            .separator {
                display: flex;
                align-items: center;
                justify-content: center;

                @include breakpoint('mobile') {
                    display: none;
                }
            }

            .value {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: getFontSize('mediumText', 'desktop');
                font-weight: getFontWeight('mediumText');
                font-style: getFontStyle('mediumText');
                font-family: getFontFamily('mediumText');

                @include breakpoint('tablet') {
                    font-size: getFontSize('mediumText', 'tablet');
                }
                @include breakpoint('mobile') {
                    font-size: getFontSize('mediumText', 'mobile');
                    text-align: left;
                    justify-content: flext-start;
                }
            }
        }
    }
}
</style>
